import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState } from '@/state'
import SVGIcon from '@/components/icon'
import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'

const ProjectStats = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { stats, project } = manageProjectContainer
  const { getRequest } = useQuery()
  const [viewsCount, setViewsCount] = useState('??')

  useEffect(() => {
    getRequest(`/api/v3/manage/projects/${project.id}/view_count`, {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setViewsCount(jsonData.viewCount)
    })
  }, [])

  return <>
    <Card title='Project Stats'>
      <ul className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to={`/manage/projects/${project.id}/submissions`} className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-submissions' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Submissions </div>
                <p className="text-green-500">{stats.submissionsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Everyone who has applied</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to={`/manage/projects/${project.id}/roles`} className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-manage-projects' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Roles </div>
                <p className="text-green-500">{stats.rolesAvailableCount} / {stats.rolesCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Pinpoint the right talent</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-followers' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Followers </div>
                <p className="text-green-500">{stats.followersCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">People who subscribe to project updates</p>
              </div>
            </div>
          </div>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-talent-search' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Project Views </div>
                <p className="text-green-500">{viewsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">People who have viewed your project</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </Card>
  </>
}
export default ProjectStats
