import React from 'react'
import PropTypes from 'prop-types'

const Check = () => {
  return <div className="flex-shrink-0">
    <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
    </svg>
  </div>
}

const NoIcon = () => {
  return <div className="flex-shrink-0">
    <svg className="h-5 w-5 text-grey-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
    </svg>
  </div>
}

const BaseBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-gray-500 dark:text-gray-400 text-center">Free</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Partially custom profile</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Add images to project / profile descriptions</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Ads</span>
      </li>
      <li className='flex space-x-3 items-center'>
        <NoIcon />
        <div className='flex items-center'>
          <img className='h-6 w-6' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg' />
          <span className="text-sm">0 cccCoins monthly</span>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Profile Listed in search and <i>not listed on other profiles</i></span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot message non-followers</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot see who viewed your profile, or profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm"><b>2</b>-person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot invite non-followers to project</span>
      </li> }
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot have project team members</span>
      </li> }
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view project analytics</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Project listed in search, not featured</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot see how many people listened to your</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Does not receive early paid notifications</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view submission analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">No events included</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot access curated content library</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private community chat</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private job board</span>
      </li>
    </ul>
  </div>
}

const ApprenticeBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-cccpurple text-center">Apprentice</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Fully custom profile</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Add images to project / profile descriptions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">No ads</span>
      </li>
      <li className='flex space-x-3 items-center'>
        <Check />
        <div className='flex items-center'>
          <img className='h-6 w-6' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg' />
          <span className="text-sm"><b className='text-cccpurple'>100</b> cccCoins monthly</span>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccpurple'>Profile Featured</b> in search, <i>not listed on other profiles</i></span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccpurple'>10</b> messages to non-followers per month</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See who viewed your profile and profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Invite <b className='text-cccpurple'>5</b> non-followers to a project</span>
      </li> }
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Can view project analytics</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Project listed in search, not featured</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View how many people listened to your to submissions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Receive early paid work notifications</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View submission analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">No events included</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot access curated content library</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private community chat</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private job board</span>
      </li>
    </ul>
  </div>
}

const MakerBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-cccblue text-center">Maker</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccblue">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Fully custom profile</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Add images to project / profile descriptions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">No ads</span>
      </li>
      <li className='flex space-x-3 items-center'>
        <Check />
        <div className='flex items-center'>
          <img className='h-6 w-6' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg' />
          <span className="text-sm"><b className='text-cccblue'>300</b> cccCoins monthly</span>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccblue'>Profile Featured</b> in search, <i>not listed on other projects</i></span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccblue'>50</b> messages to non-followers per month</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See who viewed your profile and profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccblue">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccblue'>Unlimited</b> person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Invite <b className='text-cccblue'>15</b> non-followers to a project</span>
      </li> }
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Can view project analytics</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Project listed in search, not featured</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccblue">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View how many people listened to your to submissions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Receive early paid work notifications</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View submission analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccblue">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">No events included</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot access curated content library</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private community chat</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private job board</span>
      </li>
    </ul>
  </div>
}

const BusinessBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-cccorange text-center">Business</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Fully custom profile</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Add images to project / profile descriptions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">No ads</span>
      </li>
      <li className='flex space-x-3 items-center'>
        <Check />
        <div className='flex items-center'>
          <img className='h-6 w-6' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg' />
          <span className="text-sm"><b className='text-cccorange'>700</b> cccCoins monthly</span>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>Profile Featured</b> in search <b className='text-cccorange'>and listed on other profile pages</b></span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>100</b> messages to non-followers per month</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See who viewed your profile and profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>Unlimited</b> person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Invite <b className='text-cccorange'>30</b> non-followers to a project</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Co-manage a project with team members</span>
      </li> }
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Can view project analytics</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>Project Featured</b> in search <b>and listed on other project pages</b></span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View how many people listened to your to submissions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Receive early paid work notifications</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View submission analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Live dedicated events</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Full access to curated content library</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Join private community chat</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See private job board</span>
      </li>
    </ul>
  </div>
}

const MasterBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-cccorange text-center">Master</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Fully custom profile</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Add images to project / profile descriptions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">No ads</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>Featured</b> in search <b>and on other project pages</b></span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>30</b> messages to non-followers per month</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See who viewed your profile and profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccorange'>Unlimited</b> person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Invite <b className='text-cccorange'>30</b> non-followers to a project</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Co-manage a project with team members</span>
      </li> }
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Can view project analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View how many people listened to your to submissions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Receive early paid work notifications</span>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View submission analytics</span>
      </li> }
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccorange">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Live dedicated events</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Full access to curated content library</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Join private community chat</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See private job board</span>
      </li>
    </ul>
  </div>
}

const PlanTitle = ({ planId }) => {
  if (planId === 'base') {
    return <span className='text-gray-500 dark:text-gray-300'>the free plan</span>
  } else if (planId === 'apprentice') {
    return <span className='text-cccpurple'>Apprentice</span>
  } else if (planId === 'maker') {
    return <span className='text-cccblue'>Maker</span>
  } else if (planId === 'master') {
    return <span className='text-cccorange'>Master</span>
  } else if (planId === 'professional') {
    return <span className='text-cccblue'>Professional</span>
  } else if (planId === 'business') {
    return <span className='text-cccorange'>Business</span>
  }
}

const WhatsIncluded = ({ planId, comparisonPlanId }) => {
  return <>
    <div className="bg-white dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
      <div className="flex items-center px-6 py-3">
        <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-gray-800 dark:text-gray-200">
          Compare <PlanTitle planId={comparisonPlanId} /> with <PlanTitle planId={planId} />
        </h4>
        <div className="flex-1 border-t-2 border-gray-200"></div>
      </div>
      <section className="px-6 pb-6">
        <div className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
          { comparisonPlanId === 'base' && <BaseBenefits comparison={true} /> }
          { comparisonPlanId === 'apprentice' && <ApprenticeBenefits comparison={true} /> }
          { comparisonPlanId === 'maker' && <MakerBenefits comparison={true} /> }
          { comparisonPlanId === 'master' && <MasterBenefits comparison={true} /> }
          { comparisonPlanId === 'business' && <BusinessBenefits comparison={true} /> }
          { planId === 'base' && <BaseBenefits /> }
          { planId === 'apprentice' && <ApprenticeBenefits /> }
          { planId === 'maker' && <MakerBenefits /> }
          { planId === 'master' && <MasterBenefits /> }
          { planId === 'business' && <BusinessBenefits /> }
        </div>
      </section>
      <a target="_blank" href="/pricing" className='text-clcpink px-6 pb-6 cursor-pointer flex justify-start'>
        <div>See the full plan details, including what's coming soon, here</div>
        <svg className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </a>
    </div>
  </>
}

export default WhatsIncluded

WhatsIncluded.propTypes = {
  planId: PropTypes.string.isRequired,
  comparisonPlanId: PropTypes.string.isRequired
}
MasterBenefits.propTypes = {
  comparison: PropTypes.bool
}
BaseBenefits.propTypes = {
  comparison: PropTypes.bool
}
ApprenticeBenefits.propTypes = {
  comparison: PropTypes.bool
}
BusinessBenefits.propTypes = {
  comparison: PropTypes.bool
}
MakerBenefits.propTypes = {
  comparison: PropTypes.bool
}
