import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { MobileAdBundle, SidebarAdBundle } from '@/Layout/Ads'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'
import DefaultButton from '@/shared/Buttons/Default'

import Loading from '@/shared/Loading'
import { HeartIcon, BellIcon, TrashIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <HeartIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> You don't have any saved searches. Go filter out those Gacha life projects, quick!!</span>
      </div>
    </span>
  )
}

const SavedSearchRow = ({ savedSearch }) => {
  const { putpostRequest } = useQuery()
  const [, setLoading] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [status, setStatus] = useState(savedSearch.status)
  const [error, setError] = useState(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  useEffect(() => {
    if (status === savedSearch.status) { return }

    statusChanged()
  }, [status])

  const statusChanged = () => {
    setLoading(true)

    const action = 'PATCH'
    const url = `/api/v3/saved_search_projects/${savedSearch.id}`

    putpostRequest(url, action, { saved_search_project: { status: status } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
      }
    })
  }

  const remove = () => {
    setDeleted(true)
    setLoading(true)
    putpostRequest(`/api/v3/saved_search_projects/${savedSearch.id}`, 'DELETE', { }, (err, jsonData) => {
      setLoading(false)
    })
  }

  const goToSearch = () => {
    window.location.href = `/${savedSearch.search}`
  }

  if (deleted) return null

  return <li className='bg-white dark:bg-gray-700 sm:p-1 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="flex flex-col sm:flex-row items-start space-x-0 sm:space-x-3 p-2">
      <div className="flex flex-col justify-between sm:w-96">
        <div className='dark:text-gray-300'>{ savedSearch.title }</div>
        <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex flex-col sm:flex-row">
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
            <span className="text-green-700">Email Notifications: {status}</span>
          </span>
        </div>
      </div>
      <div className="flex space-x-12">
        <div className='relative flex items-center sm:w-48'>
          <div className='flex flex-col space-y-1'>
            <span>Email Notfications</span>
            <button onClick={openDropdown} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center'>
              <span className="sr-only" hidden>Email Notifications</span>
              <BellIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              {status === 'disabled' && <>Disabled</> }
              {status === 'weekly' && <>Once a week</> }
              {status === 'daily' && <>Once a day</> }
            </button>
            { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
              <div onClick={() => setStatus('daily')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Once a day</div>
              <div onClick={() => setStatus('weekly')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Once a week</div>
              <div onClick={() => setStatus('disabled')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Disabled</div>
            </div> }
          </div>
        </div>
        <div className='w-48'> <DefaultButton onClick={goToSearch} text="View Search Results" /> </div>
        <button type="button" onClick={remove} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
          <TrashIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
          <span className="text-xs">Delete</span>
        </button>
      </div>
    </div>
  </li>
}

const SavedSearchProjectsIndex = () => {
  const [savedSearches, setSavedSearches] = useState([])
  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const scrollRef = useRef(null)

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    setLoading(true)
    getRequest('/api/v3/saved_search_projects', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setSavedSearches(jsonData.savedSearches)
    })
  }

  return <>
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='saved_searched' className='max-w-6xl w-full'>
        <Card light title='Saved Searches'>
          <>
            { loading && <Loading /> }
            <ul role="list" className='space-y-2'>
              {savedSearches.map((ss, idx) => (
                <SavedSearchRow key={`pubss${ss.id}`} savedSearch={ss} />
              ))}
            </ul>
            { savedSearches.length === 0 && !loading && <NoResults /> }
          </>
        </Card>
      </div>
    </div>
  </>
}

export default SavedSearchProjectsIndex

SavedSearchRow.propTypes = {
  savedSearch: PropTypes.object.isRequired
}
