import React, { useEffect, useRef, useState, useReducer, useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Loading from '@/shared/Loading'
import axios from 'axios'
import { patch, get } from "@rails/request.js";
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import { CheckCircleIcon, CloudUploadIcon, EmojiHappyIcon } from '@heroicons/react/solid'

// uuid
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

// 6 digit string
function generateRandomString() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  
  return result;
}

function createSlug(filename) {
  // Split the filename into base and extension
  const lastDotIndex = filename.lastIndexOf('.');
  let baseName, extension;
  
  if (lastDotIndex === -1) {
    // No extension found
    baseName = filename;
    extension = '';
  } else {
    baseName = filename.substring(0, lastDotIndex);
    extension = filename.substring(lastDotIndex);
  }
  
  // Clean up the base name
  const cleanBaseName = baseName
    .toLowerCase()
    .replace(/\s+/g, '-')       // Replace spaces with hyphens
    .replace(/[^\w\-\.]+/g, '') // Remove non-word chars except dots and hyphens
    .replace(/\-\-+/g, '-')     // Replace multiple hyphens with single hyphen
    .replace(/^-+/, '')         // Trim hyphens from start
    .replace(/-+$/, '');        // Trim hyphens from end
  
  // Return the cleaned base name with the original extension
  return extension ? cleanBaseName + extension : cleanBaseName;
}

const ProgressBar = ({ progressPercentage }) => {
  let color = 'bg-cccpurple-alt'
  if (progressPercentage > 10 && progressPercentage <= 30) {
    color = 'bg-gradient-to-r from-cccpurple-alt to-cccpurple'
  } else if (progressPercentage > 30 && progressPercentage <= 50) {
    color = 'bg-gradient-to-r from-cccpurple-alt via-cccpurple to-cccblue'
  } else if (progressPercentage > 50 && progressPercentage <= 70) {
    color = 'bg-gradient-to-r from-cccpurple via-cccblue to-cccblue-alt'
  } else if (progressPercentage > 70 && progressPercentage <= 90) {
    color = 'bg-gradient-to-r from-cccblue via-cccblue-alt to-cccorange'
  } else if (progressPercentage > 90) {
    color = 'bg-gradient-to-r from-cccblue via-cccorange to-cccorange-alt'
  }
  return (
    <div className='h-2 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }} className={`h-full ${color}`}> </div>
    </div>
  )
}

const AudioUploadForm = ({initialUrl, setUrl}) => {
  const [, setToast] = useGlobalState('toast')
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const [uploading, setUploading] = useState(false)
  const [baseMimeType, setBaseMimetype] = useState(null)
  const { getRequest, putpostRequest } = useQuery()
  const audioRef = useRef(null)
  const [progressPercentage, setProgressPercentage] = useState(0.0)
  const [file, setFile] = useState(null)

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">Accepted formats are mp3, wav, mov, and m4a. Max file size is 12MB. Wav files are typically large so we suggest converting to mp3.</p>
      </div>)
    }

    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'audio/*,video/*', maxFiles: 1 })


  const axiosConfig = {
    onUploadProgress: progressEvent => setProgressPercentage((progressEvent.loaded / progressEvent.total) * 100),
    headers: { 'X-CSRF-TOKEN': csrfToken }
  }

  const reset = () => {
    setFile(null)
    setProgressPercentage(0)
    setUploading(false)
    audioRef.current.src = initialUrl
  }

	const uploadFile = async () => {
    setUploading(true)
    var key = `audio-${generateRandomString()}-${createSlug(file.name)}`
    try {
      const response = await get(`/api/v3/audio_uploads/r2_hash?key=${key}`, {
        responseKind: "application/json",
      })

      if (response.ok) {
        const json = await response.json
        console.log('uploading to:', json.signed_url)
        
        // Using xhr because fetch doesn't support progress yet
        const xhr = new XMLHttpRequest();
        
        const success = await new Promise((resolve, reject) => {
          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              setProgressPercentage((event.loaded / event.total) * 100)
            }
          });
          
          xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4) {
              if (xhr.status >= 200 && xhr.status < 300) {
                console.log("Upload successful with status:", xhr.status);
                resolve(true);
              } else {
                setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium dark:text-red-100 text-red-600">Upload Error</p>
                  <p className="mt-1 text-sm dark:text-red-300 text-red-400">{xhr.status} If you see this, report it to moderators@castingcall.club</p>
                </div>)
                reject(new Error(`Upload failed with status: ${xhr.status}`));
              }
            }
          });
          
          xhr.addEventListener("error", (e) => {
            console.error("XHR error:", e);
            setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
              <p className="mt-1 text-sm dark:text-red-300 text-red-400">Network error during upload.</p>
            </div>)
            reject(new Error("Network error during upload"));
          });
          
          xhr.open("PUT", json.signed_url, true);
          xhr.setRequestHeader("Content-Type", file.type);
          // Directly send the file, not the FormData
          xhr.send(file);
        });
        // Verify the file exists after upload
        // NOTE: for some reason this fails sometimes
        if (false && success) {
          try {
            const verifyResponse = await fetch(verifyUrl, { method: 'HEAD' });
            
            if (verifyResponse.ok) {
              setUploading(false);
              setUrl(verifyUrl);
            } else {
              console.error("File upload succeeded but verification failed:", verifyResponse.status);
              setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
                <p className="mt-1 text-sm dark:text-red-300 text-red-400">File did not successfully upload. Try again.</p>
              </div>)
              throw new Error("File verification failed");
            }
          } catch (verifyError) {
            console.error("Error verifying file:", verifyError);
            throw verifyError;
          }
        }
        const verifyUrl = `${json.base_url}/${key}`;
        setUrl(verifyUrl);
      } else {
        console.log('Session expired or server error, please refresh the page');
        setUploading(false);
      }
    } catch (error) {
      console.error('error', error)
      setUploading(false)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Big Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">{error} If you see this, report it to moderators@castingcall.club</p>
      </div>)
    }
  }

	const uploadFile2 = async () => {
    setUploading(true)
    var key = `audio-${generateRandomString()}-${createSlug(file.name)}`
		const response = await get(`/api/v3/audio_uploads/r2_hash?key=${key}`, {
			responseKind: "application/json",
		})

		if (response.ok) {
      const json = await response.json
      console.log('uploading', json.url)

      var data = new FormData()
      data.append('Content-Type', file.type)
      data.append('Content-Encoding', 'base64')
      data.append('file', file)

      // using xhr because fetch doesnt support progress yet
      const xhr = new XMLHttpRequest();
      const success = await new Promise((resolve) => {
        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setProgressPercentage((event.loaded / event.total) * 100)
          }
        });
        xhr.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            console.log("download progress:", event.loaded / event.total);
          }
        });
        xhr.addEventListener("loadend", () => {
          resolve(xhr.readyState === 4 && xhr.status === 200);
          setUploading(false)
          setUrl(json.base_url + '/' + key)
        });
        xhr.open("PUT", json.signed_url, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.send(file);
      });
    } else {
      console.log('refresh the page. your session is expired')
      setUploading(false)
    }
  }

  useEffect(() => {
    if (!file) { return }

    let mimeType = file.type
    let leMime = mimeType.replace(/\/.*$/, "")
    if (leMime !== 'audio') {
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">Accepted formats are mp3, wav, mov, and m4a. Max file size is 12MB. Wav files are typically large so we suggest converting to mp3.</p>
      </div>)
      setUploading(false)
      return
    }
    audioRef.current.src = URL.createObjectURL(file)
  }, [file])

  return <>
    <div className="relative p-8">
      <div className="sm:text-center">
        <h2 className="text-3xl font-extrabold text-slate-900 tracking-tight sm:text-4xl">Upload Audio</h2>
      </div>
      <div className='w-70 sm:w-96 mx-auto mt-20'>
        <audio ref={audioRef} controls className='h-10 w-96 mx-auto object-cover rounded-md shadow-lg border border-2 border-black' src={initialUrl} />
        { !file && <div className="bg-gray-50 dark:bg-gray-750 mt-3 relative block min-h-20 w-full border-2 border-gray-300 flex flex-col justify-center cursor-pointer border-dashed rounded-lg p-12 text-center hover:border-gray-400" {...getRootProps()} >
          <input {...getInputProps()} />
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
            <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
              Click or drop <b>audio</b> file to upload.
              <p className='text-xs'>If your file is mp3, we will not alter it. If it's another format, we will convert it to mp3.</p>
              <p className='text-xs'>For best results, <b>mp3 format, max bitrate 192 kps, 44.1 or 48KHz, Mono</b></p>
            </span>
          </span>
        </div> }
        { !file && <div className="rounded-md bg-cccpurple dark:bg-cccorange bg-opacity-20 dark:bg-opacity-20 p-4 mt-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-cccpurple dark:text-cccorange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-cccpurple dark:text-cccorange uppercase">The project owner can listen to your original <b><i>uncompressed</i></b> raw audio file at any time.</p>
            </div>
          </div>
        </div> }

        { file && <div className='bg-gray-50 w-full mt-2 border-2 rounded-lg border-gray-300 p-2'>
          <div className='mt-2 text-sm text-gray-500 dark:text-gray-400 break-words'>{file.path} - {(file.size / 1000 / 1000).toFixed(2)} MB</div>
          { uploading && <ProgressBar progressPercentage={progressPercentage} /> }
          <div className='mt-2 flex justify-center gap-x-3'>
            <button onClick={reset} className="mt-2 group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssbeige px-3 py-2 text-slate-900 border-2 border-black">
              <span className="absolute h-0 w-0 rounded-full bg-ssbeige-alt transition-all duration-300 group-hover:h-56 group-hover:w-32"></span>
              <span className="relative">
                Reset
              </span>
            </button>
            <button onClick={uploadFile} className="mt-2 group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-cccblue px-3 py-2 text-white border-2 border-black">
              <span className="absolute h-0 w-0 rounded-full bg-cccpurple transition-all duration-300 group-hover:h-56 group-hover:w-32"></span>
              <span className="relative">
                Save
              </span>
            </button>
          </div>
        </div> }
      </div>
    </div>
  </>
}

export default AudioUploadForm
