import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { MobileAdBundle, SidebarAdBundle } from '@/Layout/Ads'
import { EyeIcon, EyeOffIcon, ExclamationIcon, SortAscendingIcon, SearchIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'
import Card from '@/shared/Card'
import SubmissionRow from '@/pages/Submissions/SubmissionRow'
import Tabs from '@/shared/Tabs'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This person hasn't listed any submissions as visible on their profile.</span>
      </div>
    </span>
  )
}

const SubmissionsIndex = () => {
  const [currentUser] = useGlobalState('currentUser')
  const user = currentUser
  const [meta, setMeta] = useState({})
  const [submissions, setSubmissions] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [statuses, setStatuses] = useState([])
  const [order, setOrder] = useState('updated_at')
  const [status, setStatus] = useState('all')
  const [permissions, setPermissions] = useState('all')
  const [searchOpen, setSearchOpen] = useState(false)
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [contains, order, permissions, status])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      contains: contains,
      order_by: order,
      permissions: permissions,
      status: status,
      page: newPage ? 1 : page
    }
    getRequest(`/api/v3/users/${user.username}/submissions`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      if (statuses.length === 0) { setStatuses(jsonData.statuses) }
      setSubmissions(jsonData.submissions)
      setMeta(jsonData.meta)
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') {
      setOrder(e.target.value)
    }
    if (e.target.name === 'permissions') {
      setPermissions(e.target.value)
    }
    if (e.target.name === 'contains') {
      setContains(e.target.value)
    }
  }

  const changeTab = (tab) => { setStatus(tab) }

  const tablist = [
    { name: 'All Submissions', count: null, changeValue: 'all' },
    { name: 'Chosen', count: statuses?.casted, changeValue: 'casted' },
    { name: 'Dismissed', count: statuses?.reject, changeValue: 'reject' }
  ]

  if (currentUser?.canViewAuditionReviewStatus) {
    tablist.push({ name: 'Reviewed', count: statuses?.reviewed, changeValue: 'reviewed' })
    tablist.push({ name: 'Unreviewed', count: statuses?.pending, changeValue: 'pending' })
  } else {
    tablist.push({ name: 'Reviewed/Unreviewed', count: statuses?.reviewed + statuses?.pending, changeValue: 'pending' })
  }

  const orderMap = {
    updated_at: 'Latest Activity',
    created_at: 'Created At',
    played_count: 'Listened To Count',
    cached_votes_up: 'Most Upvoted',
    comments_count: 'Most Commented'
  }

  return <>
    <MobileAdBundle ezoicAdId={109} />
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='submissions' className='max-w-6xl w-full'>
        <Card title='My Submissions'>
          <>
            <div ref={scrollRef}>
              <div className='flex justify-between items-center'>
                <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
                <div className='flex space-x-2 items-center'>
                  <div className='relative flex items-center'>
                    <button onClick={openDropdown} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-48'>
                      <span className="sr-only" hidden>Open options</span>
                      <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                      <span className='truncate'>{orderMap[order]}</span>
                    </button>
                    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                      <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.updated_at}</div>
                      <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.created_at}</div>
                      <div onClick={() => setOrder('played_count')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.played_count}</div>
                      <div onClick={() => setOrder('cached_votes_up')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.cached_votes_up}</div>
                      <div onClick={() => setOrder('comments_count')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.comments_count}</div>
                    </div> }
                  </div>
                  <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button type="button" onClick={() => setPermissions('all')} className={`py-1 px-2 text-xs sm:text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${permissions === 'all' ? 'text-cccblue' : ''}`}>
                      All
                    </button>
                    <button type="button" onClick={() => setPermissions('public')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${permissions === 'public' ? 'text-cccblue' : ''}`}>
                      <EyeIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                    <button type="button" onClick={() => setPermissions('private')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${permissions === 'private' ? 'text-cccblue' : ''}`}>
                      <EyeOffIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                  </div>
                  <button onClick={() => setSearchOpen(!searchOpen)} className={`py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${searchOpen ? 'text-cccblue' : ''}`}>
                    <span className="sr-only" hidden>Open search</span>
                    <SearchIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              { searchOpen && <div className='flex flex-col sm:flex-row mb-2'>
                <input name='contains' type="text" onChange={handleChange}
                  className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search by project name or role name. ex: Goku"
                />
              </div> }
              { submissions.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
            </div>
            { user.auditionsCount === 0 && <NoResults /> }
            { loading && <Loading /> }
            { status === 'pending' && !currentUser?.canViewAuditionReviewStatus && <div className="rounded-md bg-yellow-50 p-4 mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>These submissions have either been reviewed by the project owner or they are still unreviewed. Free CCC members do not have access to detailed analytics, but if you were to <Link to='/subscriptions'>upgrade</Link>, you could see which submissions were reviewed and which were not.</p>
                    <p className='mt-2'>Upgrading helps CCC keep our lights on and gives you some neat perks! Thanks!</p>
                  </div>
                </div>
              </div>
            </div> }
            <ul className="space-y-3">
              {submissions.map((sub) => (
                <SubmissionRow key={`pubsub${sub.id}`} submission={sub} voteIds={[]} />
              ))}
            </ul>
            { submissions.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
          </>
        </Card>
      </div>
      <SidebarAdBundle ezoicAdId={108} />
    </div>
  </>
}

export default SubmissionsIndex
