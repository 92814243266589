import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useGlobalState } from '@/state'

import Sidebar from '@/Layout/Sidebar'
import Header from '@/Layout/Header'
import ErrorHandler from '@/Layout/ErrorHander'
import ErrorNotification from '@/shared/ErrorNotification'
import SurveyPrompt from '@/shared/SurveyPrompt'
import { Toast } from '@/shared/Toast'
import Loading from '@/shared/Loading'

import TalentSearchContainer from '@/containers/TalentSearchContainer'
import SavedSearchProjectsContainer from '@/containers/SavedSearchProjectsContainer'
import CoinsContainer from '@/containers/CoinsContainer'
import CreditsContainer from '@/containers/CreditsContainer'
import DashboardContainer from '@/containers/DashboardContainer'
import FavoritesContainer from '@/containers/FavoritesContainer'
import AlertsContainer from '@/containers/AlertsContainer'
import ManageProjectsContainer from '@/containers/ManageProjectsContainer'
import MyRolesContainer from '@/containers/MyRolesContainer'
import AchievementsContainer from '@/containers/AchievementsContainer'
import PerksContainer from '@/containers/PerksContainer'
import PrivatePostsContainer from '@/containers/PrivatePostsContainer'
import ProfileContainer from '@/containers/ProfileContainer'
import RecommendationsContainer from '@/containers/RecommendationsContainer'
import EventsContainer from '@/containers/EventsContainer'
import SettingsContainer from '@/containers/SettingsContainer'
import SubmissionsContainer from '@/containers/SubmissionsContainer'
import SubscriptionContainer from '@/containers/SubscriptionContainer'
import UsersContainer from '@/containers/UsersContainer'
import ProjectSearchContainer from '@/containers/ProjectSearchContainer'

function usePageViews(currentUser) {
  const location = useLocation()

  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname)
      window.ga('event', 'page_view')
    }
  }, [location])
}

export default function App(props) {
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [, setReleaseInfo] = useGlobalState('releaseInfo')
  const [, setEduAds] = useGlobalState('eduAds')
  const isComponentMounted = useRef(false)

  useEffect(() => {
    setCurrentUser(props.currentUser)
    setReleaseInfo(props.releaseInfo)
    setEduAds(props.eduAds)
    isComponentMounted.current = true
  }, [])

  usePageViews()

  if (!isComponentMounted.current) {
    return <Loading full={true} />
  }

  return (
    <div className="h-screen flex overflow-hidden dark:bg-gray-700 bg-gray-100">
      <Sidebar />
      <div className="flex flex-col w-0 flex-1">
        <Header />
        <main className='overflow-y-auto'>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 pb-16">
            <ErrorHandler>
              <Switch>
                <Route {...props} exact path={['/coins']}>
                  <CoinsContainer />
                </Route>
                <Route {...props} exact path={['/credits', '/credits/new', '/credits/:id/edit', '/credits/:id/manage', '/credits/:slug']}>
                  <CreditsContainer />
                </Route>
                <Route {...props} path={['/events']}>
                  <EventsContainer />
                </Route>
                <Route {...props} path={['/dashboard', '/dashboard/profile_views']}>
                  <DashboardContainer />
                </Route>
                <Route {...props} path={['/saved_searches']}>
                  <SavedSearchProjectsContainer />
                </Route>
                <Route {...props} path={['/favorites']}>
                  <FavoritesContainer />
                </Route>
                <Route {...props} path={['/notifications']}>
                  <AlertsContainer />
                </Route>
                <Route {...props} path='/profile'>
                  <UsersContainer />
                </Route>
                <Route {...props} exact path={
                  [
                    '/manage/projects',
                    '/manage/projects/:id',
                    '/manage/projects/:id/edit',
                    '/manage/projects/:id/messages',
                    '/manage/projects/:id/submissions',
                    '/manage/projects/:id/start',
                    '/manage/projects/:id/roles'
                  ]
                }>
                  <ManageProjectsContainer />
                </Route>
                <Route {...props} path={['/myroles']}>
                  <MyRolesContainer />
                </Route>
                <Route {...props} path={['/achievements']}>
                  <AchievementsContainer />
                </Route>
                <Route {...props} path={['/submissions', '/submissions/:id']}>
                  <SubmissionsContainer />
                </Route>
                <Route {...props} exact path={['/private_messages', '/private_messages/new', '/private_messages/:id']}>
                  <PrivatePostsContainer />
                </Route>
                <Route {...props} path={['/perks']}>
                  <PerksContainer />
                </Route>
                <Route {...props} path={['/recommendations']}>
                  <RecommendationsContainer />
                </Route>
                <Route {...props} path={['/settings']}>
                  <SettingsContainer />
                </Route>
                <Route {...props} path={['/subscriptions', '/membership', '/change_card']}>
                  <SubscriptionContainer />
                </Route>
                <Route {...props} path={['/talent', '/talent/:talent_type']}>
                  <TalentSearchContainer />
                </Route>
                <Route {...props} exact path={['/find_jobs', '/category/:category', '/search', '/']}>
                  <ProjectSearchContainer />
                </Route>
                <Route {...props} path='/:username'>
                  <ProfileContainer />
                </Route>
              </Switch>
            </ErrorHandler>
          </div>
        </main>
      </div>
      <ErrorNotification />
      <Toast />
      <SurveyPrompt />
    </div>
  )
}

App.propTypes = {
  currentUser: PropTypes.object.isRequired,
  eduAds: PropTypes.array.isRequired,
  releaseInfo: PropTypes.string.isRequired
}
