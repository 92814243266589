import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'
import useQuery from '@/hooks/useQuery'
import AudioUploadForm from '@/shared/AudioUploadFormR2'
import DefaultButton from '@/shared/Buttons/Default'
import DumbAudioPlayer from '@/shared/DumbAudioPlayer'

const SubmissionAudio = ({ project, submission, selectedRole }) => {
  const objectId = submission?.id
  const [editing, setEditing] = useState(!submission?.audioUrl && !submission?.audioUploadRawUrl)
  const [remoteUrl, setRemoteURL] = useState(submission?.audioUrl || submission?.audioUploadRawUrl)
  const { getRequest, putpostRequest } = useQuery()

  const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    setRemoteURL(null)
  }

  const fetchFilePathInfo = (remoteUrl, auditionId) => {
    if (submission?.id) {
      setRemoteURL(remoteUrl)
      setEditing(false)
    } else {
      window.location.href = `/submissions/${auditionId}/edit?success=true`
    }
  }

  const createAudition = (audioUrl) => {
    const data = {
      public_audio_url: audioUrl,
      role_id: selectedRole.id,
    }

    if (submission?.id) {
      putpostRequest(`/api/v3/auditions/${submission.id}`, 'PATCH', data, (err, jsonData) => {
        if (err) { return }

        window.location.href = `/submissions/${submission.id}/edit?success=true`
      })
    } else {
      putpostRequest(`/api/v3/auditions/create_audio`, 'POST', data, (err, jsonData) => {
        if (err) { return }

        window.location.href = `/submissions/${jsonData.id}/edit?success=true`
      })
    }
  }
  

  return <div className='max-w-xl mx-auto mt-6'>
    <Card title="Audio Submission">
      <div className="flex justify-center">
        { editing && <div className='flex flex-col w-full justify-center mx-auto'>
          <div className='p-1 mx-auto'>
            <AudioUploadForm
              initialUrl={remoteUrl}
              setUrl={createAudition}
            />
          </div>
        </div> }
        { !editing && <div className='flex flex-col w-full justify-center'>
          <div className='w-full p-1'>
            <DumbAudioPlayer mediaUrl={remoteUrl} />
          </div>
          <div className='w-m p-1 mx-auto'>
            <DefaultButton className='mt-5' onClick={() => setEditing(true)} text="Upload a different file instead" />
          </div>
        </div> }
      </div>
    </Card>
  </div>
}

export default SubmissionAudio

SubmissionAudio.propTypes = {
  project: PropTypes.object.isRequired,
  selectedRole: PropTypes.object.isRequired,
  submission: PropTypes.object
}
