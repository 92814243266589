import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { achievement } from '@/shared/Toast'
import Loading from '@/shared/Loading'
import { TippyTooltip } from '@/shared/ToolTip'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  FilmIcon,
  XCircleIcon,
  LinkIcon
} from '@heroicons/react/solid'

import PublicDemoRow from '@/pages/Users/PublicProfile/PublicDemoRow'
import CommentForm from '@/shared/CommentForm'
import CommentRow from '@/shared/CommentRow'
import SubmissionRowOptions from '@/pages/Users/PublicProfile/PublicProfileSubmissionRowOptions'
import SubmissionShare from '@/pages/Users/PublicProfile/PublicProfileSubmissionRowShare'
import Permission from '@/pages/Submissions/Permission'
import { VideoPlayer } from '@/pages/Users/PublicProfile/VideoPlayer'
import GiftModal from '@/shared/GiftModal'

const SubmissionRow = ({ submission, voteIds }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const [upvotes, setUpvotes] = useState(submission.cachedVotesUp)
  const [deleted, setDeleted] = useState(false)
  const [comments, setComments] = useState([])
  const [loadingComments, setLoadingComments] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [upvoted, setUpvoted] = useState(voteIds.indexOf(submission.id) >= 0)
  const { putpostRequest, getRequest } = useQuery()

  const loadComments = () => {
    setShowComments(!showComments)
    setLoadingComments(true)
    getRequest(`/api/v3/auditions/${submission.id}/comments`, {}, (err, jsonData) => {
      setLoadingComments(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  }

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  const upvote = () => {
    if (upvoted) { return }

    if (currentUser.id !== submission.userId) {
      setUpvotes(upvotes + 1)
      setUpvoted(true)
    }

    putpostRequest(`/api/v3/auditions/${submission.id}/upvote`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ return }
      if (jsonData.achievement) {
        setToast(achievement(jsonData.achievement))
      }
      if (!jsonData.achievement && currentUser.id === submission.userId) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-red-100 text-red-600">Hey now</p>
          <p className="mt-1 text-sm dark:text-red-300 text-red-400">Silly goose. You can't upvote yourself.</p>
        </div>)
      }
    })
  }
  const incPlayedCount = () => {
    putpostRequest(`/api/v3/auditions/${submission.id}/played`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }
  if (deleted) { return null }
  return <li className='bg-white dark:bg-gray-700 px-2 py-2 sm:px-3 rounded-lg shadow-sm'>
    <div className="flex items-start justify-between flex-col space-y-2">
      <div className='flex space-x-1 items-start justify-between truncate w-full'>
        <div className="relative m-1">
          <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={submission.roleImageUrl} alt={submission.roleName} />

          <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
            <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
          </span>
        </div>
        <div className="flex-1 py-0 w-full flex flex-col truncate">
          <span className="flex">
            <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={submission.createdAt}>{submission.createdAt}</time>
            <TippyTooltip content={<>How many times this submission has been listened to or clicked on. Only available to premium members.</>}>
              <span type="button" className="ml-2 flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                <EyeIcon className="h-4 w-4" aria-hidden="true" />
                <span className="text-xs">{ currentUser.canViewAuditionPlayedCount && <>{submission.playedCount}</> }</span>
              </span>
            </TippyTooltip>
            <TippyTooltip content={<>If this submission was reviewed by project owner. A green check means they have reviewed it. Only available to premium members.</>}>
              <span type="button" className="ml-2 flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                { !currentUser.canViewAuditionPlayedCount && <QuestionMarkCircleIcon className="h-4 w-4" aria-hidden="true" /> }
                { currentUser.canViewAuditionPlayedCount && submission.listenedByOwner && <CheckCircleIcon className="text-green-400 h-4 w-4" aria-hidden="true" /> }
                { currentUser.canViewAuditionPlayedCount && !submission.listenedByOwner && <XCircleIcon className="h-4 w-4" aria-hidden="true" /> }
              </span>
            </TippyTooltip>

            <TippyTooltip content={<>Chosen means that the submission was awarded the role. Dismissed means that the project owner was looking for something else at this time.</>}>
              <>
                {submission.status === 'casted' && <span className='ml-2 flex-shrink-0 inline-block px-2 text-white bg-green-500 text-xs font-medium rounded-full flex capitalize'>
                  Chosen
                </span> }
                { currentUser.showRejectedSubmissions && submission.status === 'reject' && <span className='ml-2 flex-shrink-0 inline-block px-2 text-white bg-red-500 text-xs font-medium rounded-full flex capitalize'>
                  Dismissed
                </span> }
              </>
            </TippyTooltip>

            { submission.gifts.map((gift, index) => (
              <TippyTooltip key={`gift${gift.name}${submission.id}`} content={<>Award: {gift.name}</>}>
                <a className='flex' href="/coins">
                  <img className='h-4 w-4 ml-1' alt={gift.name} src={gift.imageUrl} /> { gift.count > 1 && <span className='text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 text-xs'>x {gift.count}</span> }
                </a>
              </TippyTooltip>
            ))}
          </span>
          <Link to={`/submissions/${submission.id}`} className="text-sm truncate w-150">{submission.projectName}</Link>
          <div><a href={`/projects/${submission.projectSlug}`} className="font-medium text-cccblue">{submission.roleName}</a></div>
          { submission.remoteLink && <div className='inline-flex justify-start w-full min-w-3 min-h-3'>
            <a onClick={incPlayedCount} href={submission.remoteLink} aria-label='Go to submission' target='_blank' rel="noreferrer" className="inline-flex items-center py-2 shadow-sm text-sm font-medium text-gray-700 text-cccblue hover:text-cccblue-alt">
              <LinkIcon className="mr-1 h-5 w-5" aria-hidden="true" />
              {submission.remoteLink}
            </a>
          </div> }
        </div>
        <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 mb-2">
          { submission.audioUrl && <PlayPauseButton
            mediaUrl={submission.audioUrl}
            objectId={submission.id}
            objectKind='Audition' />
          }
        </div>
      </div>
      { submission.videoUrl && currentUser.enabledVideos && <VideoPlayer videoUrl={submission.videoUrl} thumbnailUrl={submission.videomancyThumbnailUrl} /> }
      {submission.note && <div className="text-xs sm:text-sm text-gray-700 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: submission.note }} /> }
      { submission.demo?.id && <PublicDemoRow demo={submission.demo} /> }
      <div className="flex justify-between sm:justify-start space-x-8 items-center w-full">
        <TippyTooltip content={<>Your upvote is only visible to you and the owner of the submission.</>}>
          <button type="button" onClick={upvote} aria-label='Upvote' className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
            <ArrowCircleUpIcon className={`h-5 w-5 ${upvoted ? 'text-green-500' : ''}`} aria-hidden="true" />
            { !upvoted && <>
              { currentUser.id === submission.userId && <span className="text-xs">{upvotes} <span className='hidden sm:inline'>upvotes</span></span> }
              { currentUser.id !== submission.userId && <span className="text-xs hidden sm:inline">upvote</span> }
            </> }
            { Boolean(upvoted) && <span className="text-xs text-green-500 hidden sm:inline">upvoted!</span> }
          </button>
        </TippyTooltip>
        <button onClick={loadComments} aria-label='Load Comments' type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
          <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
          <span className="text-xs">{submission.commentsCount} <span className='hidden sm:inline'>comments</span> </span>
        </button>
        <SubmissionShare submission={submission} />
        <GiftModal giftableId={submission.id} giftableType='Audition' />
        <Permission submission={submission} />
        <SubmissionRowOptions submission={submission} setDeleted={setDeleted} />
      </div>
      <div className="w-full">
        { showComments && <>
          <CommentForm commentableId={submission.id} commentableType='Audition' addChildtoParent={addChildtoParent} setHide={setShowComments} />
          {comments.length > 0 && <>
            <div className="space-y-3 mt-3">
              {comments.map((com, idx) => (
                <CommentRow key={`com${com.id}`} initialComment={com} />
              ))}
            </div>
          </> }
        </> }
      </div>
      {loadingComments && <Loading /> }
    </div>
  </li>
}

export default SubmissionRow

SubmissionRow.propTypes = {
  submission: PropTypes.object.isRequired,
  voteIds: PropTypes.array.isRequired
}
